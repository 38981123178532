
.draw-col {
    width: 100%;
}

.draw-row {
    --bs-gutter-x: 0.75rem;
}

.draw-item {
    visibility: hidden;
}

.ball {
    position: absolute;
    width: 25px;
    height: 25px;
}

.ball-symbol {
    position: relative;
    width: 20px;
    height: 20px;
    padding-left: 5px;
    padding-bottom: 2px;
}

.ball-txt {
    padding-left: 3px;
}

.bingo-card-cont {
    width: 250px;
}

.bingo-card-img {
    position: relative;
    width: 250px;
}

.card-spot-row {
    width: 250px;
    position: absolute;
    --bs-gutter-x: 0.75rem;
    padding-top: 10px;
}

.spot-star {
    position: relative;
    object-fit: cover;
    width: 35px;
    height: 35px;
    top:40px;
    z-index: 1;
    opacity: .7;
    visibility: hidden;
}

.spot-symbol {
    position: relative;
    width: 35px;
    height: 35px;
    z-index: 0;
}

.spot-txt {
    text-align: center;
    height: fit-content;
    padding-top: 11px;
    padding-left: 2px;
    z-index: 0;
}

.interim-prize {
    position: relative;
    width: 75px;
    height: 75px;
    visibility: hidden;
}

.end-prize {
    position: relative;
    width: 33%;
    height: 75px;
    visibility: hidden;
}